import { useEffect, useState } from 'react'
const isBrowser = (): boolean => {
  return Boolean(typeof window !== 'undefined' && window.document && window.document.createElement)
}

export const useStorage = <T>(
  key: string,
  raw?: boolean,
): [T | null, (value: T | null) => void] => {
  if (!isBrowser()) return [null, () => {}]
  const getStorageValue = () => {
    try {
      const value = localStorage.getItem(key)
      if (!value || value === '') return null
      if (raw) return String(value)
      const result = JSON.parse(value)
      return typeof result !== 'object' ? null : result
    } catch (err) {
      return null
    }
  }

  const [state, setState] = useState<T | null>(() => getStorageValue())

  const setStorage = (value: T | null) => {
    const serializedValue = raw
      ? String(value)
      : typeof value === 'string'
      ? value
      : JSON.stringify(value)
    setState(value)
    localStorage.setItem(key, value === null ? '' : serializedValue)
  }

  useEffect(() => {
    const changeHandler = () => {
      const value = getStorageValue()
      setState(value)
    }
    window.addEventListener('storage', changeHandler)
    window.addEventListener('local-storage', changeHandler)
    return () => {
      window.removeEventListener('storage', changeHandler)
      window.removeEventListener('local-storage', changeHandler)
    }
  }, [])

  return [state, setStorage]
}
