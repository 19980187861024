import { createContext, useContext } from 'react'

type User = {
  name?: string
}

export type IDashboardSharedContext = {
  user: User | null
  updateUser: () => void
}

export const DashboardSharedContext = createContext<IDashboardSharedContext>({
  user: null,
  updateUser: () => {},
})

export const useDashboardShared = () => useContext<IDashboardSharedContext>(DashboardSharedContext)
