import React from 'react'
import { Providers } from '~/components'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Home, Auth, Dashboard } from '~/pages'

const RootRoutes = () => {
  return (
    <Routes>
      <Route
        path="/board"
        element={
          <Providers.DashboardShared>
            <Dashboard />
          </Providers.DashboardShared>
        }
      />
      <Route path="/auth" element={<Auth />} />
      <Route path="/" element={<Home />} />
    </Routes>
  )
}

const AppRouter: React.FC<unknown> = () => (
  <BrowserRouter>
    <RootRoutes />
  </BrowserRouter>
)

export default AppRouter
