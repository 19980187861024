const THEME_STORE_KEY = 'theme'

export enum Theme {
  Dark = 'dark',
  Light = 'light',
}

export const getThemeFromStorage = () => localStorage?.getItem(THEME_STORE_KEY) as Theme

export const setThemeToStorage = (theme: Theme) => localStorage?.setItem(THEME_STORE_KEY, theme)

export const isValidThemeName = (type: Theme) => Object.values(Theme).includes(type)
