import { useToasts } from '@geist-ui/react'
import { PropsWithChildren, useMemo } from 'react'
import { useAsync, DashboardSharedContext, IDashboardSharedContext, useStorage } from '~/hooks'
import { STORAGE_KEYS } from '~/utils/constants'

export const DashboardShared = ({ children }: PropsWithChildren<unknown>) => {
  // const [, setToast] = useToasts()
  const [storageUser, setStorageUser] = useStorage<IDashboardSharedContext['user'] | null>(
    STORAGE_KEYS.DASHBOARD_AUTH_USER,
  )

  const updateUser: IDashboardSharedContext['updateUser'] = () => {
    // findSomethingForUser()
    //   .then(user => setStorageUser(user))
    //   .catch(err => {
    //     setToast({ type: 'warning', text: err?.message })
    //   })
  }

  // useAsync(async () => {
  //   if (storageUser) return
  //   updateUser()
  // }, [storageUser])

  const value = useMemo<IDashboardSharedContext>(
    () => ({
      user: storageUser,
      updateUser,
    }),
    [storageUser],
  )

  return <DashboardSharedContext.Provider value={value}>{children}</DashboardSharedContext.Provider>
}
