import React from 'react'

export type AppConfigsType = {
  token: string | null
  updateToken: (token: string | null) => void
  onThemeChange: () => void
}

export const defaultAppConfig: AppConfigsType = {
  token: null,
  updateToken: () => {},
  onThemeChange: () => {},
}

export const AppConfigContext = React.createContext<AppConfigsType>(defaultAppConfig)

export const useAppConfigs = () => React.useContext<AppConfigsType>(AppConfigContext)
