import React, { useMemo, useState } from 'react'
import { AppConfigContext, AppConfigsType, defaultAppConfig, useAsync } from '~/hooks'

export type AppConfigProps = {
  onThemeChange: () => void
}

export const AppConfigs: React.FC<React.PropsWithChildren<AppConfigProps>> = ({
  onThemeChange,
  children,
}) => {
  const [token, setToken] = useState<string | null>(defaultAppConfig.token)
  const value = useMemo<AppConfigsType>(
    () => ({
      token,
      updateToken: t => setToken(t),
      onThemeChange,
    }),
    [token],
  )

  useAsync(async isMounted => {
    // const endpoints = await getSomethingForApp()
    // if (!isMounted()) return
    // if (!Array.isArray(endpoints)) return
    // setEndpoint(endpoints)
  }, [])

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>
}
