/// <reference path="../types/jsx.d.ts" />
import 'inter-ui/inter.css'
import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PageRouter from './routes'
import { ConfigProvider } from 'antd'
import { useDomClean } from '~/hooks'
import { Providers } from '~/components'
import zhCN from 'antd/lib/locale/zh_CN'
import { CssBaseline, GeistProvider } from '@geist-ui/react'
import { getThemeFromStorage, isValidThemeName, setThemeToStorage, Theme } from '~/utils/themes'

const Index: React.FC<unknown> = () => {
  const [themeType, setThemeType] = useState<Theme>(Theme.Light)
  const themeChangeHandler = useCallback(() => {
    setThemeType((type: Theme) => (type === Theme.Dark ? Theme.Light : Theme.Dark))
  }, [])

  useEffect(() => {
    const theme = getThemeFromStorage()
    if (!isValidThemeName(theme)) return

    setThemeType(theme)
  }, [])
  useDomClean()
  useEffect(() => setThemeToStorage(themeType), [themeType])

  return (
    <ConfigProvider locale={zhCN}>
      <GeistProvider themeType={themeType}>
        <CssBaseline />
        <Providers.AppConfigs onThemeChange={themeChangeHandler}>
          <PageRouter />
        </Providers.AppConfigs>
        <style jsx global>{`
          html,
          body {
            overflow: hidden;
            box-sizing: border-box;
          }
        `}</style>
      </GeistProvider>
    </ConfigProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root'),
)
