import React, { useEffect } from 'react'
import { Button, Page, Text } from '@geist-ui/react'
import { useNavigate } from 'react-router-dom'
import { useAppConfigs, useDashboardShared, useDelay } from '~/hooks'

const Dashboard: React.FC<unknown> = () => {
  const navigate = useNavigate()
  const { token, updateToken } = useAppConfigs()

  const { setDelay2000ms, removeDelay2000ms } = useDelay(2000)
  const logout = () => {
    updateToken(null)
  }

  useEffect(() => {
    if (token) return removeDelay2000ms()
    setDelay2000ms(() => navigate('/auth'))
  }, [token])

  return (
    <Page>
      <div className="content">
        <Text h2>Dashboard</Text>
        {!token && <Text>Unauthorized, going to the authentication page...</Text>}
        {token && <Text>Welcome, current token: {token}</Text>}
        {token && <Button onClick={logout}>Logout</Button>}
      </div>
      <style jsx>{`
        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </Page>
  )
}

export default Dashboard
