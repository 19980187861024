import React from 'react'
import { Button, Page, Text, Spacer } from '@geist-ui/react'
import { useNavigate } from 'react-router-dom'

const Home: React.FC<unknown> = () => {
  const navigate = useNavigate()

  return (
    <Page>
      <div className="content">
        <Text h2>Hashing Home</Text>
        <Spacer h={1} />
        <Button onClick={() => navigate('/board')}>Redirect to Board</Button>
      </div>
      <style jsx>{`
        .content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </Page>
  )
}

export default Home
