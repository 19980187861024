import { useEffect, useRef } from 'react'

export type DelayHandler = (fn: () => unknown, time?: number) => void

export type DelayRemover = () => void

export type DelayReturnType<T extends number> = {
  [K in keyof Record<string, unknown> as `setDelay${T}ms`]: DelayHandler
} &
  {
    [K in keyof Record<string, unknown> as `removeDelay${T}ms`]: DelayRemover
  }

export const useDelay = <T extends number>(delay: T): DelayReturnType<T> => {
  const timer = useRef<number | null>(null)

  const removeDelay = () => {
    timer.current && clearTimeout(timer.current)
  }
  const setDelay: DelayHandler = (fn, time) => {
    timer.current = window.setTimeout(() => {
      fn()
      removeDelay()
    }, time || delay)
  }

  useEffect(() => removeDelay, [])

  return {
    [`setDelay${delay}ms`]: setDelay,
    [`removeDelay${delay}ms`]: removeDelay,
  }
}
